(function () {
    'use strict';

    angular.module("mfgApp")
        .controller("MfgDashboardCtrl", MfgDashboardCtrl);

    MfgDashboardCtrl.$inject = ["$scope", "$http", "$rootScope", "$uibModal", "rbacService", "Notification",
        "messageService", "allPermissions", "routesConstant", "CommonService", "PageService"];

    function MfgDashboardCtrl($scope, $http, $rootScope, $uibModal, rbacService, Notification, messageService,
                              allPermissions, routesConstant, CommonService, PageService) {

        angular.extend($rootScope, {
            reject: reject
        });

        var MFG;

        (function init() {

            MFG = $scope.MFG = $rootScope.MFG = routesConstant.MFG;

            PageService.setCurrentPage({
                title: undefined,
                type: undefined,
                instructions: "(To Be Added)",
                actions: []
            });

            PageService.setTitle("Operations Dashboard");

            initUserProfile();

            var $header = $(".header");
            $header.css("position", "relative");

            messageService.getMessages().then(function (config) {
                var activeMessages = config.getMessagesByTarget('Mfg');
                var now = new Date().getTime();
                _.each(activeMessages, function (msg) {
                    var start = new Date(msg.startDt).getTime();
                    var end = new Date(msg.endDt).getTime();
                    if (start < now && now < end) {
                        Notification.success({
                            message: msg.message,
                            delay: false
                        });
                    }
                });
            });
        }());


        function reject() {
            $state.go(MFG.DASHBOARD.stateName);
        }

        /*  Loads profile for a user within an organization
         * */
        function initUserProfile() {
            var url = CommonService.baseUrl + "/admin/api/userprofile";

            $http.get(url).success(function (data) {
                $scope.me = data;
                $rootScope.me = data;

                //Set up Role Access here
                rbacService.getScope($scope);
                $scope.allow = {};
                rbacService.setAllPermissions(allPermissions);
                _.each(_.keys(allPermissions.adminPermissions), function (permission) {
                    $scope.allow[permission] = rbacService.checkMfgAccess(permission);
                });
            });
        }
    }
}());
